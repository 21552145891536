.comment {
    margin-bottom: 50px;
}

.comment__main {
    display: flex;
    flex-direction: row;
}

.comment__avi__div {
    margin: 0px 10px;
}

.comment__avi {
    height: 70px;
    width: 70px;
    border-radius: 200px;
}

.comment__content {
    margin: 20px 0px;
    max-width: 700px;
}

.comment_datePosted {
    color: grey;
    margin-bottom: 5px;
}

.comment__showReplies {
    color: #3ea6ff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.comment__interactions {
    display: flex;
    flex-direction: row;
}

.comment__interactions_likeCount {
    margin: 0px 10px;
}

.comment__interactions_reply {
    margin: 0px 10px;
    padding: 0px;
}

.comment__replies {
    display: block;
}

.comment__showReplies_div {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 70px;
}

.comment__showReplies_div_no_indent {
    display: flex;
    flex-direction: column;
}

.comment__showReplies_dropDown {
    margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
    .comment__content {
        margin: 20px 0px;
        width: 200px;
    }
}