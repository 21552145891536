.footer {
    background-color: #101522;
}

.footer__wrapper {
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
}

.footer__links_container {
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

.footer__links_wrapper {
    display: flex;
    flex-direction: column;
}

.footer__link_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 10px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #FFFFFF;
}

.footer__link_title {
    font-size: 14px;
    margin-bottom: 16px;
}

.footer__link {
    color: #FFFFFF;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.footer__link:hover {
    color: #01BF71;
    transition: 0.3s ease-out;
}

.footer__social_media {
    max-width: 1000px;
    width: 100%;
}

.footer__social_media_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;
}

.footer__social_logo {
    color: #FFFFFF;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
}

.footer__website_rights {
    color: #FFFFFF;
    margin-bottom: 16px;
}

.footer__social_icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.footer__social_icon_link {
    color: #FFFFFF;
    font-size: 24px;
}

@media only screen and (min-width: 480px) {
    .footer__link_items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 16px;
        text-align: left;
        width: 160px;
        box-sizing: border-box;
        color: #FFFFFF;
    }
}


@media only screen and (min-width: 768px) {
    .footer {
        display: flex;
        justify-content: center;
        padding-top: 0;
    }

    .footer__links_wrapper {
        display: flex;
        flex-direction: row;
    }

    .footer__social_media_wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 1100px;
        margin: 40px auto 0 auto;
    }
}
