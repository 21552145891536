.dashboard {
    min-height: 500px;
    padding: 20px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2E2E2E;
    color: #FFFFFF;
}

.dashboard__title {
    padding: 50px 0px;
}

.dashboard__header {
    padding: 25px 0px;
}

.drink__image > img {
    height: 300px;
    object-fit: contain;
}

.drink__imageNTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    cursor: pointer;
    background-color: #ecf1ee;
    color: black;
    border-radius: 15px;
}

@media screen and (min-width: 768px) {
    .drink__imageNTitle {
        display: flex;
        flex-direction: row;
    }

    .drink__image {
        flex: 1;
    }
}

@media screen and (min-width: 420px) {
    .drink__image {
        margin: 50px;
    }
}
