.commentBox {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.commentBox > div {
    display: flex;
    flex-direction: column;
}

.commentBox__buttons {
    display: flex;
    flex-direction: row-reverse;
}

.commentBox__buttons > button {
    margin: 10px;
}

.commentBox__textarea {
    width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
    resize: none;
}

.commentBox__replyBox__textarea {
    width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
    resize: none;
}

@media screen and (min-width: 480px) {
    .commentBox__textarea {
        width: 100%;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }

    .commentBox__replyBox__textarea {
        width: 100%;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }
}
/*
//TODO: Find a solution that won't push the width of the replies perpetually to the right. Maybe after two levels
*/

@media screen and (min-width: 768px) {
    .commentBox__textarea {
        width: 600px;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }

    .commentBox__replyBox__textarea {
        width: 500px;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }
}

@media screen and (min-width: 992px) {
    .commentBox__textarea {
        width: 800px;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }

    .commentBox__replyBox__textarea {
        width: 600px;
        line-height: 1.5;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 1px 1px 1px #999;
        resize: none;
    }
}