.hero {
    background: #0C0C0C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 450px;
    position: relative;
    z-index: 1;
}

.hero__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.hero__video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232A34;
}

.hero__content {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero__btnWrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero__content > h1 {
    color: #FFFFFF;
    font-size: 32px;
    text-align: center;
}

.hero__content > p {
    margin-top: 24px;
    color: #FFFFFF;
    font-size: 17px;
    text-align: center;
    max-width: 600px;
}

@media screen and (min-width: 480px) {
    .hero__content > h1 {
        color: #FFFFFF;
        font-size: 40px;
        text-align: center;
    }

    .hero__content > p {
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .hero__content > h1 {
        color: #FFFFFF;
        font-size: 48px;
        text-align: center;
    }

    .hero__content > p {
        color: #FFFFFF;
        font-size: 24px;
        text-align: center;
    }
}
