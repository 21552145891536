.search_bar {
    display: flex;
}

.search_bar__full_flex {
    flex: 1;
}

.search_bar > .MuiInputBase-root > input {
    color: white;
}

.search_bar > button {
    color: white;
    background-color: #01BF71;;
}

.search_bar > button:hover {
    color: #01BF71;
    background-color: white;
}

.search_bar__small_screen {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
