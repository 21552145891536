.userFavDrinks > h3 {
    margin-bottom: 20px;
    margin-left: 20px;
}

.userFavDrinks__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userFavDrinks__empty > svg {
    padding-bottom: 5px;
}

.userFavDrinks__grid {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 30px;
}

@media only screen and (min-width: 680px) {
    .userFavDrinks__grid {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 30px 30px;
    }
}

@media only screen and (min-width: 1200px) {
    .userFavDrinks__grid {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 40px 40px;
    }
}
