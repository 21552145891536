.card {
    justify-content: center;
}

.card > .MuiPaper-root {
    display: inline-block;
}

.card__content {
    display: flex;
    padding: 25px 16px !important;
    min-height: 110px;
}

.card__info {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.card__link {
    text-decoration: none;
}

.card__fav__container > svg {
    cursor: pointer;
}

.hide-card {
    display: none
}