.commentListRenderer {
    width: 375px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 480px) {
    .commentListRenderer {
        width: 350px;
    }
}

@media screen and (min-width: 768px) {
    .commentListRenderer {
        width: 620px;
    }
}

@media screen and (min-width: 992px) {
    .commentListRenderer {
        width: 800px;
    }
}

