.sideBar {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0D0D0D;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
}

.sideBar--viewSidebar {
    opacity: 1.0;
}

.sideBar--hideSidebar {
    opacity: 0;
}

.sideBar--zeroTop {
    top: 0;
}

.sideBar--negativeTop {
    top: -100%;
}

.sideBar__cancel {
    position: absolute;
    top: 1.2rem;
    right:1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

.sideBar--viewSidebar > .sideBar__menu {
    color: #FFFFFF;
    margin-top: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-around;
}

.sideBar__menu > a {
    text-decoration: none;
}

.sideBar__menu > a > p {
    color: #FFFFFF;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;
}

.sideBar__menu > a > p:hover {
    color: #01BF71;
}

.sideBar__menu > div > p:hover {
    color: #01BF71;
}

.sideBar__menu > div > p {
    color: #FFFFFF;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
