.info--dark {
    color: #FFFFFF;
    background: #010606;
}

.info--light {
    color: #FFFFFF;
    background: #F9F9F9;
}

.info__wrapper {
    display: grid;
    z-index: 1;
    min-height: 600px;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 24px;
    justify-content: center;
}

.info__row_img_right {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col1' 'col2 col2';
}

.info__row_img_left {
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1' 'col2';
}

.info__column1 {
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
}

.info__column2 {
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
}

.info__text_wrapper {
    padding-top: 0;
    padding-bottom: 60px;
    text-align: center;
}

.info__topline {
    color: #01BF71;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.info--heading-dark {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: #F7F8FA;
}

.info--heading-light {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: #010606;
}

.info--subtitle-dark {
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}

.info--subtitle-light {
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #010606;
}

.info__img_wrap {
    height: 100%;
}

.info__img_wrap > img {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
}

@media only screen and (min-width: 480px) {

    .info--heading-dark {
        margin-bottom: 24px;
        font-size: 48px;
        line-height: 1.1;
        font-weight: 600;
        color: #F7F8FA;
    }

    .info--heading-light {
        margin-bottom: 24px;
        font-size: 48px;
        line-height: 1.1;
        font-weight: 600;
        color: #010606;
    }
}

@media only screen and (min-width: 768px) {
    .info__row_img_right {
        display: grid;
        grid-auto-columns: minmax(auto, 1fr);
        align-items: center;
        grid-template-areas: 'col1 col2';
    }

    .info__row_img_left {
        display: grid;
        grid-auto-columns: minmax(auto, 1fr);
        align-items: center;
        grid-template-areas: 'col2 col1';
    }

    .info__text_wrapper {
        max-width: 540px;
        padding-top: 0;
        padding-bottom: 60px;
        text-align: left;
    }
}
