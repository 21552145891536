.drink {
    background: #333333;
    display: flex;
    min-height: 500px;
}

.drink__container {
    display: flex;
    flex-direction: column;
    background-color: #ecf1ee;
    width: 100%;
}

.drink__imageNTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
}

.drink__detailed_info {
    display: flex;
    flex-direction: column;
    margin: 0 50px 50px;
    max-width: 1200px;
}

.drink__image {
    margin: 50px;
}

.drink__image > img {
    height: 300px;
    object-fit: contain;
}

.drink__info {
    text-align: center;
    margin: 0 0 50px;
}

.drink__title {
    margin-bottom: 50px;
}

.drink__ingredients {
    margin-bottom: 50px;
}

.drink__ingredients__label {
    margin-bottom: 10px;
}

.drink__instructions__label {
    margin-bottom : 10px;
}

.drink__fav__icon {
    margin-left: auto;
    margin-right: 0;
}

.drink__fav__icon > svg {
    cursor: pointer;
    margin-top: 15px;
}

@media (min-width: 576px) {
    .drink__container {
        display: flex;
        flex-direction: column;
        margin: 0 50px;
        background-color: #ecf1ee;
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .drink__container {
        display: flex;
        flex-direction: column;
        margin: 0 50px;
        background-color: #ecf1ee;
        width: 100%;
    }

    .drink__imageNTitle {
        display: flex;
        flex-direction: row;
    }

    .drink__image {
        flex: 1;
    }

    .drink__info {
        flex: 1;
        margin: 50px;
        text-align: initial;
    }

    .drink__detailed_info {
        flex-direction: row;
        margin: 0 50px 50px;
    }

    .drink__ingredients {
        flex: 1;
        margin-bottom: 0;
    }

    .drink__instructions {
        flex: 1;
    }
}
