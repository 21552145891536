.search {
    background: #2E2E2E;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    min-height: 500px;
}

.search__resultsContainer {
    padding: 50px 0 0;
}

.search__notfound_image {
    height: 200px;
}

.search__notfound_text {
    margin: 20px;
}

.search__loadingContainer {
    margin: auto;
}

.search__loading_text {
    margin: 20px 0;
}

.search__emptyResultsContainer {
    margin: auto;
}
