.navigation {
    color: white;
    display: flex;
    height: 85px;
    padding: 0 20px;
    background-color: black;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navigation::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navigation {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.navigation__icons {
    display: none;
}

.navigation__menu_icon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navigation__menu_icon--no_auth {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navigation__menu_icon--full_flex {
    flex: 1;
}

.navigation__logo {
    color: #FFFFFF;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.64rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    letter-spacing: .05rem;
}

.navigation__icon {
    color: white;
    display: flex;
    flex-direction: column;
    width: 100px;
    cursor: pointer;
    text-decoration: none;
}

.navigation__icon--active > p {
    display: inline !important;
}

.navigation__icon > p {
    font-size: small;
    display: none;
}

.navigation__icon:hover {
    color: #01BF71;
}

.navigation__icon:hover > p {
    display: inline;
}

.navigation__icon > .MuiSvgIcon-root{
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 992px) {
    .navigation__icons {
        display: flex;
        align-items: center;
        text-align: center;
    }
    .navigation__menu_icon {
        display: none;
    }
}
