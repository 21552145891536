.signup_main {
    background: #333333;
    display: flex;
    min-height: 600px;
}

.signup__container {
    background-color: #ecf1ee;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup_form {
    display: flex;
    flex-direction: column;
    width: 354px;
    height: 300px;
    justify-content: space-between;
    margin: 0 30px;
}

.login_link {
    text-decoration: underline;
    color: #20b30c;
}

.login_link:hover {
    text-decoration: none;
}

.second_firm {
    display: block;
}

.another_class {
    width: 100%;
}

.confirm_form {
    margin: 20px 0px;
}

@media (min-width: 576px) {
    .signup__container {
        margin: 0 50px;
        background-color: #ecf1ee;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signup_form {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 300px;
        justify-content: space-between;
        margin: 0 30px;
    }
}

@media screen and (min-width: 768px) {
    .signup__container {
        margin: 0 50px;
        background-color: #ecf1ee;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signup_form {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 300px;
        justify-content: space-between;
        margin: 0 30px;
    }
}