.user {
    min-height: 500px;
    padding: 20px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2E2E2E;
    color: #FFFFFF;
}

